
export const DEFAULT_GATEWAY        : string = 'gateway-435icoye.uc.gateway.dev'
export const PAYMENTS_GATEWAY       : string = 'payments-435icoye.uc.gateway.dev'
export const ENDPOINT_HW            : string = `https://${DEFAULT_GATEWAY}/helloWorld`
export const ENDPOINT_128           : string = `https://${DEFAULT_GATEWAY}/server128`
export const ENDPOINT_128_B         : string = `https://${DEFAULT_GATEWAY}/server128_B`
export const ENDPOINT_256           : string = `https://${DEFAULT_GATEWAY}/server256`
export const ENDPOINT_512           : string = `https://${DEFAULT_GATEWAY}/server512`
export const ENDPOINT_PAYMENTS      : string = `https://${PAYMENTS_GATEWAY}/payments`
export const ENDPOINT_LOCAL         : string = `http://127.0.0.1:5001/slap-kard/us-central1/test`
export const ENABLE_ERROR_LOGGING   : boolean = true 

