import React, { useEffect, useState } from "react"
import axios from 'axios';
import { Buffer } from "buffer";
import CryptoJS from 'crypto-js'; // npm install @types/crypto-js
import { ENABLE_ERROR_LOGGING } from "../settings/project-settings";

 
const testPW = "3892771772442081"
const testFilename = "BEqab2ObOXN1Vrbu6t7Ojn9JMG"

export default() => {


    const [testData, setTestData ] = useState("")
    useEffect(()=>{

        ( async ()=>{

            const response = await axios.get('https://api.github.com/repos/FabulouslyFullStack/public-test/contents/' + testFilename );
            if( ENABLE_ERROR_LOGGING ) console.log(response.data.content)
            const buffer = Buffer.from(response.data.content, 'base64').toString('utf-8')
            setTestData(decryptWithAES(testPW, buffer))
            //if( ENABLE_ERROR_LOGGING ) console.log(encryptWithAES(testPW, buffer))
        })()
    },[])
    return (
        <>
            This is a test :
            {testData}
        </>
    )
}

const getRandomNumber = (length : number ) => {
    length = Number(length ? length : 10);
    var result = '';
    var characters ='0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

const encryptWithAES = ( password : string, text : string ) => {
    return CryptoJS.AES.encrypt(text, password).toString();
};
  
const decryptWithAES = (password : string, ciphertext : string ) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, password);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
};